<template>
  <section>
     <b-field label="ID">
          <b-input v-model="configID"></b-input>
      </b-field>
      <b-field label="Product Name">
          <b-input v-model="configName"></b-input>
      </b-field>
      <b-field label="Battery Size (wH)">
          <b-input v-model="configBattery"></b-input>
      </b-field>
      <b-field label="Inverter (w)">
          <b-input v-model="configInverter"></b-input>
      </b-field>
      <b-field label="Charger (w)">
          <b-input v-model="configCharger"></b-input>
      </b-field>
      <b-button @click="provisionUnit" :loading="isLoading">Provision Unit</b-button>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: "Provisioning",
  components: {},
  data() {
    return {
      configID: "ID",
      configName: "powerQuad Pro",
      configInverter: 500,
      configCharger: 360,
      configBattery: 2500,
      isLoading: false
    }
  },
  methods: {
    provisionUnit () {
      this.isLoading = true;
      let data = { "inverter": this.configInverter, "charger": this.configCharger, "battery": this.configBattery, "productName": this.configName};

      axios
      .post(process.env.VUE_APP_API_BASE + this.configID + '/console/provision/', data, {
        headers: {
          'X-Amz-Security-Token':  this.$store.getters.getToken
        }
      })
      .then(response => this.$buefy.toast.open(`Success! Message Id: ${response.data}`)) 
      .catch(function (error) {
        console.log(JSON.stringify(error));
      })
      .finally(() => this.isLoading = false)  
    }
  }
};
</script>
