<template>
<section v-if="unitID">
  <b-button  @click="getLogs" :type="isPolling ? 'is-success' : 'is-info'" class="mb-5" :loading="isLoading">
    Get Logs
    <b-checkbox size="is-small" type="is-white"  class="ml-1 mt-1" v-model="isPolling"></b-checkbox>
  </b-button>
  <b-button  @click="pinPanel" class="ml-2">
     <b-icon icon="thumbtack" :type="isPinned ? 'is-success' : ''" class="mr-1"></b-icon>
    {{isPinned ? 'Unpin Logs Panel' : 'Pin Logs Panel'}}
  </b-button>

  <b-table :data="dataTable" :columns="columns" :sticky-header="true" height="500"></b-table>

</section>
<section v-else>
  <EmptyID @activetab= "$emit('activetab', $event)" />
</section>
</template>

<script>
import axios from 'axios';
import EmptyID from '@/components/EmptyID.vue';

export default {
  name: "Logs",
  components: { EmptyID },
  props: ['unitID'],
  data() {
    return {
      data: [],
      isLoading: false,
      isPolling: false,
      polling: null,
      columns: [{
          field: 'DataTimeEntry',
          label: 'DateTime',
        },
        {
          field: 'log',
          label: 'Log Data',
        }
      ]
    }
    },
  methods: {
    getLogs(){
      let vm = this;
       axios
          .post('https://rtym35lx1a.execute-api.eu-west-2.amazonaws.com/Proto/test/' + this.unitID)
          .then(response => (vm.data = response.data))
    },
    pinPanel() {
      if (!this.isPinned) {
        this.$store.commit('SET_LOGSPINNED', true);
      }
      else {
        this.$store.commit('SET_LOGSPINNED', false);
      }      
    }
  },
  computed: {
    dataString() {
      return this.data.map(e => {
        return  new Date(e.DataTimeEntry).toISOString().split('.')[0] + " : " + e.log;
      }).join('\n');
    },
    dataTable() {
      return this.data.map(e => ({
        DataTimeEntry: new Date(e.DataTimeEntry).toISOString().split('.')[0],
        log: e.log
      }))
    },
    isPinned() {
      return this.$store.getters.getIsLogsPinned;
    }
  },
  watch:{
    isPolling(){
      if (this.isPolling) {
        let vm = this;
        this.polling = setInterval(async function(){ await vm.getLogs(); }, 3000);
      }
      else {
        clearInterval(this.polling);
      }
    }
  },
  created() {
    axios.interceptors.request.use((config) => {
      this.isLoading = true;
      return config;
    }, (error) => {
      this.isLoading = false;
      return Promise.reject(error);
    });

    axios.interceptors.response.use((response) => {
      this.isLoading = false;
      return response;
    }, (error) => {
      this.isLoading = false;
      return Promise.reject(error);
    });
  }
};
</script>
