<template>
<div class="level-item has-text-centered">
  <div>
    <p class="heading">{{title}}</p>
    <p class="title">{{val}}</p>
  </div>
</div>
</template>

<script>
export default {
  name: "CalLEvel",
  components: {},
  props: ['title', 'val']
};
</script>
