<template>
  <b-field>
    <span class="control">
      <b-button @click="sendMessage" type="is-info" class="mr-2 mb-2">{{message.name}}</b-button>
    </span>  
  </b-field>
</template>

<script>
import axios from 'axios';

export default {
  name: "SendCalButton",
  props: ['unitID', 'message', 'sendHex'],
  data() {
    return{
      data: {}
    }
  },
  methods: {
    sendMessage() {
      this.data.hex = { "hex_command": parseInt(this.sendHex, 16), "data": this.message.data};

      axios
      .post(process.env.VUE_APP_API_BASE + this.unitID + '/console/hex/', this.data, {
        headers: {
          'X-Amz-Security-Token':  this.$store.getters.getToken
        }
      })
      .then(response => this.$buefy.toast.open(`Success! Message Id: ${response.data.MessageId}`)) 
      .catch(function (error) {
        console.log(JSON.stringify(error));
      }); 
    }
  }
};
</script>

<style scoped>
.button.is-link {
    background-color: #3273dc;
    border-color: transparent;
    color: #fff;
}
.button.is-info.is-light {
    background-color: #eef6fc;
    color: #1d72aa;
}
.data-input {
  width: 110px;
}
</style>