<template>
 <b-button @click="sendHex" :type="type" :icon-left="icon">{{title}}</b-button>
</template>

<script>
import axios from 'axios';

export default {
  name: "SendHexButton",
  props: ['unitID', 'hex', 'icon', 'title', 'type'],
  data() {
    return{
      data: {}
    }
  },
  methods: {
    sendHex(){
       this.data.hex = { "hex_command": parseInt(this.hex, 16), "data": "0"};

        axios
        .post(process.env.VUE_APP_API_BASE + this.unitID + '/console/hex/', this.data, {
          headers: {
            'X-Amz-Security-Token':  this.$store.getters.getToken
          }
        })
        .then(response => this.$buefy.toast.open(`Success! Message Id: ${response.data.MessageId}`)) 
        .catch(function (error) {
          console.log(JSON.stringify(error));
        }); 
    }
  },
};
</script>
