<template>    
   <div id="chart">     
      <apexchart type="line" height="450" :options="chartOptions" :series="data"></apexchart>
    </div>
</template>

<script>
export default {
  name: 'Chart',
  props: ['UnitID', 'data'],
  data: function() {
    return {
      series: [{
              name: "Desktops",
              data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
          }],
          chartOptions: {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: true
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'straight'
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            legend: {
              show: true
            }
          },
    }
  },
  watch: {
  //   chartData () {
  //     this.series = this.chartData[0];
  //     this.options = {
  //     ...this.options,
  //     ...{
  //       xaxis: {
  //         type: 'datetime',
  //         categories: this.chartData[1]
  //       },
  //     },
  //   };
  //   }
  }

}
</script>

<style scoped>
.chart-fullheight{ 
  height: 100%;
}
</style>
