<template>
  <section v-if="unitID">
      Coming soon ... <br><br>
      <b-button @click="$emit('activetab', 0)" type="is-info" >Go to Units</b-button>
  </section>
  <section v-else>
  <EmptyID @activetab= "$emit('activetab', $event)" />
</section>
</template>

<script>
import EmptyID from '@/components/EmptyID.vue';

export default {
  name: "BatteryHealth",
  components: { EmptyID },
  props: ['unitID']
};
</script>
