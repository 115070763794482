<template>
  <b-field>
    <b-input
      v-model="sendData"
      v-if="item.hasData"
      class="data-input"
      :placeholder="hint"
    ></b-input>
    <span class="control">
      <b-button @click="sendMessage" type="is-info" class="mr-2 mb-2">{{
        item.name
      }}</b-button>
    </span>
  </b-field>
</template>

<script>
import axios from "axios";
import { getToken } from "../session";
//https://48927fuz4c.execute-api.eu-west-2.amazonaws.com/testV2/settings/boost/0000000001

export default {
  name: "SendMessageButton",
  props: ["unitID", "item"],
  data() {
    return {
      sendData: "",
      data: {},
    };
  },
  methods: {
    sendHex() {
      axios
        .get(
          "https://48927fuz4c.execute-api.eu-west-2.amazonaws.com/testV2/" +
            this.unitID
        )
        .then((response) => {
          this.$buefy.snackbar.open({
            message:
              '<i class="fas fa-check-circle"></i>' +
              response +
              "! Message sent: " +
              this.item.hex,
            position: "is-top",
            duration: 3000,
          });
        })
        .catch((error) => {
          this.$buefy.snackbar.open({
            message: '<i class="fas fa-check-circle"></i> Error!' + error,
            position: "is-top",
            type: "is-danger",
            duration: 4000,
          });
        });
    },
    async sendMessage() {
      if (this.item.hasData && this.sendData == "") {
        // this.$buefy.dialog.prompt({
        //         message: 'Data:',
        //         trapFocus: true,
        //         onConfirm: (value) => this.$buefy.toast.open(`Your data is: ${value}`)
        //     })
        this.$buefy.snackbar.open({
          message:
            '<i class="fas fa-exclamation-circle"></i> This command requires data!',
          position: "is-top",
          type: "is-danger",
          duration: 3000,
        });
      } else {
        // this.$buefy.snackbar.open({
        //     message: '<i class="fas fa-check-circle"></i> Does Not Have Data',
        //     position: 'is-top',
        //     duration: 3000})
        //keyObj.hex = { "hex_command": hexValue, "data": hexData};
        //this.data.hex = { "hex_command": parseInt(this.item.attr, 16), "data": this.sendData};
        const token = await getToken();
        let hexObj = {};
        hexObj.hex_command = parseInt(this.item.attr, 16)
        if (this.sendData != ''){
          hexObj.data = this.sendData;
        } else {
          hexObj.data = 0;
        }
        this.data.hex = hexObj;

        axios
        .post(process.env.VUE_APP_API_BASE + this.unitID + '/console/hex/', this.data, {
          headers: {
            'X-Amz-Security-Token':  token
          }
        })
        //.then(response => this.$buefy.toast.open(`Success! Message Id: ${response.data.MessageId}`)) 
        .then(response => this.$buefy.toast.open(`Success! ${JSON.stringify(this.data.hex)} Message Id: ${response.data.MessageId}`)) 
        .catch(function (error) {
          console.log(JSON.stringify(error));
        }); 
      }
    },
  },
};
</script>

<style scoped>
.button.is-link {
  background-color: #3273dc;
  border-color: transparent;
  color: #fff;
}
.button.is-info.is-light {
  background-color: #eef6fc;
  color: #1d72aa;
}
.data-input {
  width: 110px;
}
</style>
