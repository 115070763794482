<template>
  <section>
    <b-table
      :data="isEmpty ? [] : data"
      :bordered="isBordered"
      :striped="isStriped"
      :narrowed="isNarrowed"
      :hoverable="isHoverable"
      :loading="isLoading"
      :focusable="isFocusable"
      :mobile-cards="hasMobileCards"
    >
      <b-table-column
        field="Id"
        label="ID"
        width="100"
        :searchable="true"
        v-slot="props"
      >
        {{ props.row.Id }}
      </b-table-column>

      <b-table-column field="agile" label="Agile" v-slot="props">
        <span>
          <b-icon pack="fas" :icon="props.row.Agile === 'True' ? 'check' : ''">
          </b-icon>
        </span>
      </b-table-column>

      <b-table-column label="" v-slot="props">
        <b-button @click="LogIn(props.row.Id)" type="is-success"
          >Log In</b-button
        >
      </b-table-column>

      <b-table-column label="" v-slot="props">
        <form
          action="https://app.powerquad.co.uk/login/"
          method="post"
          target="_blank"
        >
          <input
            type="hidden"
            id="fname"
            name="fname"
            :value="'*' + props.row.Id"
          />
          <input type="hidden" id="lname" name="lname" value="pq18" />
          <b-button native-type="submit" type="is-info"
            >View Dashboard</b-button
          >
        </form>
        <!-- <b-button  @click="Dashboard(props.row.Id)" type="is-info">View Dashboard</b-button> -->
      </b-table-column>

      <!-- <b-table-column field="date" label="Date" centered v-slot="props">
                <span class="tag is-success">
                    {{ new Date(props.row.date).toLocaleDateString() }}
                </span>
            </b-table-column> -->
    </b-table>
  </section>
</template>

<script>
import axios from "axios";
import { getToken } from "../session.js";

export default {
  name: "Units",
  data() {
    return {
      data: [],
      unit: "",
      isEmpty: false,
      isBordered: false,
      isStriped: false,
      isNarrowed: false,
      isHoverable: false,
      isFocusable: false,
      isLoading: true,
      hasMobileCards: true,
    };
  },
  methods: {
    LogIn(id) {
      this.unit = id;
      this.$emit("unit", id);
    },
    Dashboard(id) {
      window.open("https:app.powerquad.co.uk/?" + id, "_blank");
    },
  },
  async mounted() {
    let vm = this;
    const token = await getToken();
    axios
      .get(process.env.VUE_APP_API_BASE + "**/settings", {
        headers: {
          "X-Amz-Security-Token": token,
        },
      })
      .then((response) => (vm.data = response.data.Items))
      .catch(function(error) {
        console.log(JSON.stringify(error));
      });
  },
  created() {
    axios.interceptors.request.use(
      (config) => {
        this.isLoading = true;
        return config;
      },
      (error) => {
        this.isLoading = false;
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        this.isLoading = false;
        return response;
      },
      (error) => {
        this.isLoading = false;
        return Promise.reject(error);
      }
    );
  },
};
</script>
