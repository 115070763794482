<template>
  <b-navbar class="container">
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img src="../assets/logo.png" alt="Logo" />
      </b-navbar-item>
    </template>
    <template slot="end">
      <!-- <a v-show="isAuth" @click="logout" class="button is-link mt-1" href="https://app-powerquad-apitest.auth.eu-west-2.amazoncognito.com/logout?client_id=3da9ou0ga8a10300ab1ul5ntlt&logout_uri=https://app.powerquad.co.uk/testapi">Log Out</a> -->
      <b-button v-show="isAuth" @click="showToken" class="mt-1" type="is-white"
        ><b-icon type="is-info" icon="key"></b-icon
      ></b-button>
      <a v-show="isAuth" @click="logout" class="button is-link mt-1">Log Out</a>
    </template>
  </b-navbar>
</template>

<script>
import { Auth } from "../../node_modules/aws-amplify";
import { getToken } from "../session";
export default {
  name: "TheNavBar",
  props: ["unit"],
  computed: {
    isAuth() {
      return this.$store.getters.getIsAuth;
    },
  },
  methods: {
    async logout() {
      try {
        await Auth.signOut();
        this.$store.commit("SET_AUTH", false);
        this.$router.push({ name: "Login" });
      } catch (e) {
        console.log(e);
      }
    },
    async showToken() {
      const token = await getToken();
      this.$buefy.snackbar.open({
        message: token,
        position: "is-top",
        duration: 4000,
      });
    },
  },
};
</script>

<style scoped>
.token-icon {
  margin-top: 5px;
}
</style>
