<template>
  <section v-if="unitID">
    <section class="level">
      <CalLevel v-for="(item, index) in groups" :key="index" :title="item.label" val="50" />      
    </section>

     <section class="buttons">
            <SendHexButton @click.native="start" v-if="!isRunning" :unitID="unitID" title="Start" hex="0x07" icon="" type="is-success"/>
            <SendHexButton @click.native="stop" v-if="isRunning" :unitID="unitID" title="Stop" hex="0x0B" icon="" type="is-danger"/>
            <SendHexButton :unitID="unitID" title="CT Range" hex="0x08" icon="angle-up"  type="is-info"/>
            <SendHexButton :unitID="unitID" title="CT Range" hex="0x09" icon="angle-down" type="is-info"/>
            <SendHexButton :unitID="unitID" title="Next Cal State" hex="0x0A" icon="" type="is-info"/>
            <SendHexButton :unitID="unitID" title="Send" hex="0x3B" icon="" type="is-info"/>
            <SendHexButton :unitID="unitID" title="Save" hex="0x27" icon="" type="is-info"/>
      </section>
      
     <fieldset v-for="(item, index) in groups" :key="index" class="box">
      <legend class="label">{{item.label}}</legend>
      <b-field grouped group-multiline>
      <SendCalButton v-for="groupID in item.group" :key="groupID" :unitID="unitID"
        :message="vars[vars.findIndex(item => item.data == groupID)]" :sendHex="item.attr"/> 
        <b-input placeholder="Data" v-model="calData[item.val]"></b-input>
      </b-field>
    </fieldset>
  </section>
  <section v-else>
  <EmptyID @activetab= "$emit('activetab', $event)" />
</section>
</template>

<script>
import EmptyID from '@/components/EmptyID.vue';
import SendCalButton from '@/components/SendCalButton.vue';
import SendHexButton from '@/components/SendHexButton.vue';
import CalLevel from '@/components/CalLevel.vue';
import axios from 'axios';

export default {
  name: "Calibration",
  components: { EmptyID, SendCalButton, CalLevel, SendHexButton },
  props: ['unitID'],
  data() {
    return {
      vars: [
        { data: 'Q', name: '+1'},
        { data: 'W', name: '+5'},
        { data: 'E', name: '+10'},
        { data: 'A', name: '-1'},
        { data: 'S', name: '-5'},
        { data: 'D', name: '-10'},        
      ],
      groups: [
        { label: 'Mains Voltage Offset', group: ["Q","W","E","A","S","D"], val: 'mainsVoltage', attr: '0x20'},
        { label: 'Inverter Voltage Offset', group: ["Q","W","E","A","S","D"], val: 'inverterVoltage', attr:'0x21'},
        { label: 'Mains Voltage Gain', group: ["Q","W","E","A","S","D"], val: 'mainsVoltage', attr: '0x22'},
        { label: 'Inverter Voltage Gain', group: ["Q","W","E","A","S","D"], val: 'inverterVoltage', attr: '0x23'},
      ],
      isRunning: false,
      refreshRate: 2000,
      liveData: [],
      calData: {mainsVoltage: 'Data', inverterVoltage: 'Data'},
    }
  },
  methods: {
    start() {
      this.isRunning = true;
      this.pollLiveData();
    },
    stop() {
      this.isRunning = false;
    },
    save() {
      //save
    },
    send() {
      //send
    },
    startTest() {
      alert('alert');
    },
    getLive(){
       axios
      .get(process.env.VUE_APP_API_BASE + this.unitID + '/data/live', {
        headers: {
          'X-Amz-Security-Token':  this.$store.getters.getToken
        }
      })
      .then(response => (this.liveData = response.data.Items))
      .catch(function (error) {
        console.log(JSON.stringify(error));
      }); 
    },
    pollLiveData() {
      console.log('Poll');
      this.getLive();
      if(this.isRunning == true) {
        setTimeout(() => this.pollLiveData(), this.refreshRate);
      }
    }
  },
  watch: {
    liveData(obj) {
      if(obj.length > 0) {
        this.calData.mainsVoltage = obj[0].VoltageMeterings[0].VoltageReading;
        this.calData.inverterVoltage = obj[0].VoltageMeterings[1].VoltageReading;
      }
    }
  }
};
</script>
