import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { isLoggedIn } from "../session";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "Home",
    component: Home,
    meta: {
      title: 'powerQuad Console | Dashboard'
    }    
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
      meta: {
        title: 'powerQuad Console | Login'
      }  
  },
  {
    path: "/signup",
    name: "Signup",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Signup.vue"),
      meta: {
        title: 'powerQuad Console | Signup'
      }  
  },
  {
    path: "/password_reset",
    name: "ForgotPassword",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/ForgotPassword.vue"),
      meta: {
        title: 'powerQuad Console | Forgot Password'
      }  
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title || 'powerQuad Console'; //set page title

  const loggedIn = await isLoggedIn();
  if (to.name === "Home" && !loggedIn) next({ name: "Login" });
  else if (to.name === "Login" && loggedIn) next({ name: "Home" });
  else next();
});

export default router;
