import { Auth } from "aws-amplify";

// Refreshes the session and returns true if the session is valid.
export async function isLoggedIn() {
  return await Auth.currentSession()
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
}

// Gets the token required to authenticate API calls from the current session object.
// Also refreshes the session if it is invalid.
export async function getToken() {
  const session = await Auth.currentSession();
  return session.idToken.jwtToken;
}
