<template>
  <section v-if="unitID">     
      <b-field grouped>
          <b-button type="is-info" @click="getWaveform('CT')" :loading="isLoading" class="mr-1">Get CT</b-button>
          <b-button type="is-info" @click="getWaveform('0')" :loading="isLoading" class="mr-1">Get Mains</b-button>
          <b-button type="is-info" @click="getWaveform('1')" :loading="isLoading" class="mr-1">Get Inverter</b-button>
          <b-select v-model="sendHex">
              <option value="0x24">CT</option>
              <option value="0x57">Mains</option>
              <option value="0x58">Inverter</option>
          </b-select>
          <b-input v-model="sendHex" placeholder="Hex" class="b-input"></b-input>          
          <b-input v-model="sendData" placeholder="Data" class="b-input"></b-input>
          <SendCalButton :unitID="unitID" :sendHex="sendHex" :message="message"/>
          
      </b-field>
     
      <section v-if="waveData_CT.length > 0">
        <p>Sample: {{index+1}} of {{waveData_CT.length}}</p>

         <h3 class="title is-4">
          <b-button icon-right="chevron-left" @click="moveIndex('CT',-1)"></b-button>
            {{ctChartData[0].name}}
          <b-button icon-right="chevron-right" @click="moveIndex('CT',1)" ></b-button>
        </h3>
        <Chart :data="ctChartData"/>
      </section>
      <section v-else>
        <p>No Samples</p>
      </section>

      <section v-if="waveData_0.length > 0">
        <p>Sample: {{index_0+1}} of {{waveData_0.length}}</p>

         <h3 class="title is-4">
          <b-button icon-right="chevron-left" @click="moveIndex('0',-1)"></b-button>
            {{c0ChartData[0].name}}
          <b-button icon-right="chevron-right" @click="moveIndex('0',1)" ></b-button>
        </h3>
        <Chart :data="c0ChartData"/>
      </section>
      <section v-else>
        <p>No Samples</p>
      </section>

      <section v-if="waveData_1.length > 0">
        <p>Sample: {{index_1+1}} of {{waveData_1.length}}</p>

         <h3 class="title is-4">
          <b-button icon-right="chevron-left" @click="moveIndex('0',-1)"></b-button>
            {{c1ChartData[0].name}}
          <b-button icon-right="chevron-right" @click="moveIndex('0',1)" ></b-button>
        </h3>
        <Chart :data="c1ChartData"/>
      </section>
      <section v-else>
        <p>No Samples</p>
      </section>

      

  </section>
  
  <section v-else>
  <EmptyID @activetab= "$emit('activetab', $event)" />
</section>
</template>

<script>
import EmptyID from '@/components/EmptyID.vue';
import Chart from '@/components/Chart.vue';
import SendCalButton from '@/components/SendCalButton.vue';
import axios from 'axios';

export default {
  name: "Waveforms",
  components: { Chart, EmptyID, SendCalButton },
  props: ['unitID'],
  data() {
    return {
      waveData: [], // make this a computed of the others below. or have it as an array of arrays
      waveData_CT: [],
      waveData_0: [],
      waveData_1: [],
      isLoading: false,
      index: 0,
      index_0: 0,
      index_1: 0,
      sendHex: '0x24',
      sendData: '0'
    }
  },
  methods: {
    getWaveform(type){
      this.index = 0;
      if(type=="CT") {
        axios
        .get(process.env.VUE_APP_API_BASE + this.unitID + '/console/waveform/' + type, {
          headers: {
            'X-Amz-Security-Token':  this.$store.getters.getToken
          }
        })
        .then(response => (this.waveData_CT = response.data.Items)) 
        .catch(function (error) {
          console.log(JSON.stringify(error));
        });
      }
      else if(type=="0") {
        axios
        .get(process.env.VUE_APP_API_BASE + this.unitID + '/console/waveform/' + type, {
          headers: {
            'X-Amz-Security-Token':  this.$store.getters.getToken
          }
        })
        .then(response => (this.waveData_0 = response.data.Items)) 
        .catch(function (error) {
          console.log(JSON.stringify(error));
        });
      }
      else if(type=="1") {
        axios
        .get(process.env.VUE_APP_API_BASE + this.unitID + '/console/waveform/' + type, {
          headers: {
            'X-Amz-Security-Token':  this.$store.getters.getToken
          }
        })
        .then(response => (this.waveData_1 = response.data.Items)) 
        .catch(function (error) {
          console.log(JSON.stringify(error));
        });
      }
      

    },
    moveIndex(type, val){
      let length = 0;
      let index = 0;
      if(type == 'CT') {
        length = this.waveData_CT.length;
        index = this.index;
        if (!(index == length-1 && val > 0) && !(index == 0 && val < 0)) {
          this.index = index + val;
        }
      }
      else if(type == '0') {
        length = this.waveData_0.length;
        index = this.index_0;
        if (!(index == length-1 && val > 0) && !(index == 0 && val < 0)) {
          this.index_0 = index + val;
        }
      }
      else if(type == '1') {
        length = this.waveData_1.length;
        index = this.index_1;
        if (!(index == length-1 && val > 0) && !(index == 0 && val < 0)) {
          this.index_1 = index + val;
        }
      }

          
    }
  },
  created() {
    axios.interceptors.request.use((config) => { //isLoading with axios
      this.isLoading = true;

      return config;
    }, (error) => {
      this.isLoading = false;
      return Promise.reject(error);
    });

    axios.interceptors.response.use((response) => {
      this.isLoading = false;
      return response;
    }, (error) => {
      this.isLoading = false;
      return Promise.reject(error);
    });
  },
  computed: {
    ctChartData () {
      let series = '';
      if (this.waveData_CT.length > 0 ){
        series = {};
        if (this.waveData_CT[this.index].WaveType == "CT") {
          series.name = this.waveData_CT[this.index].CTChannel + '_' + this.waveData_CT[this.index].DataReceivedTime;
          series.data = this.waveData_CT[this.index].CTSamples;
        }
        else {
          let waveName = this.waveData_CT[this.index].WaveType == 0 ? 'Mains' : 'Inverter';
          series.name = waveName + '_' + this.waveData_CT[this.index].DataReceivedTime;
          series.data = this.waveData_CT[this.index].MainsAll;
        }
        
      }
      return [series];
    },
    c0ChartData () {
      let series = '';
      if (this.waveData_0.length > 0 ){
        series = {};
        let waveName = 'Mains'
        series.name = waveName + '_' + this.waveData_0[this.index_0].DataReceivedTime;
        series.data = this.waveData_0[this.index_0].MainsAll;    
      }
      return [series];
    },
    c1ChartData () {
      let series = '';
      if (this.waveData_1.length > 0 ){
        series = {};     
        let waveName = 'Inverter'
        series.name = waveName + '_' + this.waveData_1[this.index_1].DataReceivedTime;
        series.data = this.waveData_1[this.index_1].MainsAll;
      }
      return [series];
    },
    indexActive(){
      return this.waveData_CT.length == this.index;
    },
    message() {
      let obj = {};
      obj.name = "Send Hex";
      obj.data = this.sendData;
      return obj;
    }
  }
};
</script>
<style scoped>
.b-input{
  max-width: 100px;
}
</style>