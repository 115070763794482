<template>
  <section>
    <section v-if="unitID" class="columns is-vcentered logged-in">
      You are Logged into unit:
      <span class="has-text-weight-bold ml-1"> {{ unitID }} </span>
      <form
        action="https://app.powerquad.co.uk/login/"
        method="post"
        target="_blank"
      >
        <input type="hidden" id="fname" name="fname" :value="'*' + unitID" />
        <input type="hidden" id="lname" name="lname" value="pq18" />
        <b-button native-type="submit" type="is-info" class="ml-4"
          >View Dashboard</b-button
        >
      </form>
    </section>
    <div class="columns">
      <div class="column">
        <b-tabs v-model="activeTab" @input="tabChanged">
          <b-tab-item label="Units">
            <Units @unit="LoggedIn" />
          </b-tab-item>

          <b-tab-item label="Data">
            <Data
              :unitID="unitID"
              :token="token"
              @activetab="activeTab = $event"
            />
          </b-tab-item>

          <b-tab-item label="Messaging">
            <Messaging :unitID="unitID" @activetab="activeTab = $event" />
          </b-tab-item>

          <b-tab-item label="Logs">
            <Logs :unitID="unitID" @activetab="activeTab = $event" />
          </b-tab-item>

          <b-tab-item label="Calibration">
            <Calibration :unitID="unitID" @activetab="activeTab = $event" />
          </b-tab-item>

          <b-tab-item label="Waveforms">
            <Waveforms :unitID="unitID" @activetab="activeTab = $event" />
          </b-tab-item>

           <b-tab-item label="Battery Health">
            <BatteryHealth :unitID="unitID" @activetab="activeTab = $event" />
          </b-tab-item>

          <b-tab-item label="Query">
            <Query
              :unitID="unitID"
              :token="token"
              @activetab="activeTab = $event"
            />
          </b-tab-item>

          <b-tab-item label="Provisioning">
            <Provisioning :unitID="unitID" @activetab="activeTab = $event" />
          </b-tab-item>

        </b-tabs>
      </div>
      <div v-if="isPinned" class="column is-two-fifths">
        <Logs @activetab="activeTab = $event" />
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { getToken } from "../session.js";

import Units from "@/components/Units.vue";
import Logs from "@/components/Logs.vue";
import Data from "@/components/Data.vue";
import Calibration from "@/components/Calibration.vue";
import Messaging from "@/components/Messaging.vue";
import Query from "@/components/Query.vue";
import Waveforms from "@/components/Waveforms.vue";
import BatteryHealth from "@/components/BatteryHealth.vue";
import Provisioning from "@/components/Provisioning.vue";

export default {
  name: "Home",
  components: {
    Units,
    Logs,
    Data,
    Calibration,
    Messaging,
    Query,
    Waveforms,
    BatteryHealth,
    Provisioning
  },
  data() {
    return {
      activeTab: 0,
      showBooks: false,
      unitID: null,
      token: "",
    };
  },
  methods: {
    LoggedIn(id) {
      let token = getToken();
      this.unitID = id;
      axios
        .get(process.env.VUE_APP_API_BASE + this.unitID + "/settings", {
          headers: {
            "X-Amz-Security-Token": token,
          }
        })
        .then((response) => (this.$store.commit("SET_SETTINGS", response.data.Items)))
    },
    Dashboard() {
      window.open("https:app.powerquad.co.uk/?" + this.unitID, "_blank");
    },
    tabChanged() {
      if (!this.$store.getters.getIsAuth) {
        this.$router.push("/login");
      }
    },
  },
  watch: {
    unitID() {
      //let vm = this;
      axios
        // .post("https://app.powerquad.co.uk/login/user/token.php", {uid: vm.unitID})
        .get(
          "https://app.powerquad.co.uk/login/user/token.php?uid=" + this.unitID
        ) //exchange unit ID for token
        .then((response) => (this.token = response.data));
    },
  },
  computed: {
    isPinned() {
      return this.$store.getters.getIsLogsPinned;
    },
  },
};
</script>

<style scoped>
.logged-in {
  position: absolute;
  left: 50%;
  top: -35px;
  z-index: 999;
}
.pinned-pannel {
  float: right;
}
</style>
