<template>
  <section v-if="unitID">
    {{JSON.stringify(unitSettings)}}
    <!-- {{JSON.stringify(unitCharge)}}
    {{JSON.stringify(unitDischarge)}} -->
      <!-- <SendMessageButton v-for="(item, index) in vars" :key="item.attr" :name="item.name" 
        :hex="item.attr" :unitID="unitID" :type="(index%2 == 0) ? 'is-info' : 'is-link'"
        :hasData="item.hasData" :dataHint="item.dataHint"/> -->

      <!-- <SendMessageButton name="Update Pic" hex="0x?" :unitId="unitID" type="is-danger" :hasData="true"/> -->
    <div class="buttons">
      <fieldset class="box">
        <b-field grouped>
           <b-input v-model="sendHex" placeholder="Hex"></b-input>
           <b-input v-model="sendData" placeholder="Data"></b-input>
          <SendCalButton :unitID="unitID" :sendHex="sendHex" :message="message"/>
        </b-field>
      </fieldset>
    </div>

    <div class="buttons">
      <fieldset class="box">
        <legend class="label">Controls</legend>
          <SendSettingsButton :unitID="unitID" type="boost" name="Boost" /> 
          <SendSettingsButton :unitID="unitID" type="boost" name="Boost with Interval" :hasData="true" /> <!--hint="Minutes"/>--> 
          
           <!-- <b-field grouped>
            <b-input v-model="unitSettings[1].stopwatch[0].Start_Time" placeholder="Hex"></b-input>
            <b-input v-model="unitSettings[1].stopwatch[0].Stop_Time" placeholder="Data"></b-input>
            <b-input v-model="unitSettings[1].stopwatch[1].Start_Time" placeholder="Hex"></b-input>
            <b-input v-model="unitSettings[1].stopwatch[1].Stop_Time" placeholder="Data"></b-input>
            <SendSettingsButton :unitID="unitID" type="charge" name="Update Charge"/> 
          </b-field>

          <b-field grouped>
            <b-input v-model="unitSettings[1].stopwatch_discharge[0].Start_Time" placeholder="Hex"></b-input>
            <b-input v-model="unitSettings[1].stopwatch_discharge[0].Stop_Time" placeholder="Data"></b-input>
            <b-input v-model="unitSettings[1].stopwatch_discharge[1].Start_Time" placeholder="Hex"></b-input>
            <b-input v-model="unitSettings[1].stopwatch_discharge[1].Stop_Time" placeholder="Data"></b-input>
            <SendSettingsButton :unitID="unitID" type="charge" name="Update Discharge"/> 
          </b-field> -->

      </fieldset>
    </div>

    <fieldset v-for="(item, index) in groups" :key="index" class="box">
      <legend class="label">{{item.label}}</legend>
      <b-field grouped group-multiline>
      <SendMessageButton v-for="groupID in item.group" :key="groupID+Math.random().toString().substr(2, 8)" :unitID="unitID"
        :item="vars[vars.findIndex(item => item.attr == groupID)]"/> 
      </b-field>
    </fieldset>

  </section>
  <section v-else>
  <EmptyID @activetab= "$emit('activetab', $event)" />
</section>
</template>

<script>
import EmptyID from '@/components/EmptyID.vue';
import SendMessageButton from '@/components/SendMessageButton.vue';
import SendSettingsButton from '@/components/SendSettingsButton.vue';
import SendCalButton from '@/components/SendCalButton.vue';

export default {
  name: "Messaging",
  components: { EmptyID, SendMessageButton, SendSettingsButton, SendCalButton },
  props: ['unitID'],
  data() {
    return {
      vars: [
        { attr: '0x74', name: 'Get Software Versions'},
        { attr: '0x72', name: 'Enable Standard Logs'},
        { attr: '0x61', name: 'Enable Extended Logs'},
        { attr: '0x62', name: 'Disable Extended Logs'},
        { attr: '0x65', name: 'Reset Mains ADC'},
        { attr: '0x66', name: 'Reset Inverter ADC'},
        { attr: '0x67', name: 'Reset SPI'},
        { attr: '0x68', name: 'Reset I2C'},
        { attr: '0x69', name: 'Reset Battery LTC Device'},
        { attr: '0x7C', name: 'Send I2C2STAT bytes'},
        { attr: '0x80', name: 'Update PIC Software', hasFile: true},
        { attr: '0x18', name: 'Update Pi software'},
        { attr: '0x73', name: 'Pic Reset'},
        { attr: '0x75', name: 'Force PIC MCLR Reset'},
        { attr: '0x59', name: 'Switch ON/OFF Watchdog'},
        { attr: '0x20', name: 'Change Mains Voltage Offset', hasData: true },
        { attr: '0x22', name: 'Change Mains Voltage Gain', hasData: true },
        { attr: '0x57', name: 'Get Mains Wave'},
        { attr: '0x70', name: 'Get Mains FSM Status'},
        { attr: '0x21', name: 'Change Inverter Offset', hasData: true },
        { attr: '0x23', name: 'Change Inverter Gain', hasData: true },
        { attr: '0x58', name: 'Get Inverter Wave'},
        { attr: '0x71', name: 'Get Inverter FSM Status'},
        { attr: '0x5A', name: 'Get Inverter Status'},
        { attr: '0x77', name: 'Get Charger Status'},
        { attr: '0x60', name: 'Set Battery Charger', hasData: true },
        { attr: '0x7E', name: 'Send RTC'},
        { attr: '0x34', name: 'Initialise Charger Stopwatches'},
        { attr: '0X79', name: 'Get subcont. Stopwatches_set_flags'},
        { attr: '0x7A', name: 'Get subcont. Stopwatch_charger_init_asked_for_count'},
        { attr: '0x7B', name: 'Get subcont. Stopwatchs_asked_for_pi_count'},
        { attr: '0x7D', name: 'Start full charge cycle'},
        { attr: '0x64', name: 'Send Raw Charger 0 Data '},
        { attr: '0x64', name: 'Send Raw Charger 1 Data '},
        { attr: '0x64', name: 'Send Raw Charger 2 Data '},
        { attr: '0x78', name: 'Set Battery Sampling average', hasData: true },
        { attr: '0x60', name: 'Set Battery Charge Value', hasData: true },
        { attr: '0x63', name: 'Set all metering Data to pi frequency', hasData: true },
        { attr: '0x76', name: 'CT Metering averaging', hasData: true },
        { attr: '0x24', name: 'Get CT Raw Values', hasData: true },
        { attr: '0x7F', name: 'Send ALL EEPROM'},
        { attr: '0x26', name: 'Eeprom Memory R/W test'},
        { attr: 'Reset_Rtc', name: 'Reset RTC'},
        { attr: 'Charger_SW_Data', name: 'Charger SW All Data'},
        { attr: 'Charger_SW1_Data', name: 'Charger SW 1 Data'},
        { attr: 'Charger_SW2_Data', name: 'Charger SW 2 Data'},
        { attr: 'Send_Charger_SW', name: 'Send Charger SW'},
        { attr: 'Charger_Boost_Data', name: 'Charger Boost Data'},
        { attr: 'Area1_Mains', name: 'Area 1 Mains'},
        { attr: 'Area1_Inverter', name: 'Area 1 Inverter'},
        { attr: 'Area2_Mains', name: 'Area 2 Mains'},
        { attr: 'Area2_Inverter', name: 'Area 2 Inverter'},
        { attr: 'Area3_Mains', name: 'Area 3 Mains'},
        { attr: 'Area3_Inverter', name: 'Area 3 Inverter'},
        { attr: 'Area4_Mains', name: 'Area 4 Mains'},
        { attr: 'Area4_Inverter', name: 'Area 4 Inverter'},
        { attr: 'Battery1_Selected', name: 'Battery 1 Selected'},
        { attr: 'Battery1_NotSelected', name: 'Battery 1 Not Selected'},
        { attr: 'Battery1_Charge', name: 'Battery 1 Charge'},
        { attr: 'Battery1_Not Use', name: 'Battery 1 Not Use'}
    ],
    groups: [
      { label: 'CPU', group: ["Reset_Rtc","0x74","0x72","0x61","0x62","0x65","0x66","0x67","0x68","0x69","0x7C","0x80","0x18","0x73","0x75","0x59"]},
      { label: 'Calibration', group: ["0x65","0x20","0x22","0x57","0x70","0x66","0x21","0x23","0x58","0x71","0x5A","0x77","0x60"]},
      { label: 'Stop Watches', group: ["Reset_Rtc","0x7E","0x34","Charger_SW_Data","Charger_SW1_Data","Charger_SW2_Data","Send_Charger_SW","0x64","0x64","0x64","Charger_Boost_Data","0x77","0x5A","0X79","0x7A","0x7B","0x7D","0x67"]},
      { label: 'Area Control', group: ["Area1_Mains","Area1_Inverter","Area2_Mains","Area2_Inverter","Area3_Mains","Area3_Inverter","Area4_Mains","Area4_Inverter","0x77","0x70","0x5A","0x71"]},
      { label: 'Battery Metering', group: ["0x78","Battery1_Selected","Battery1_NotSelected","Battery1_Charge","Battery1_Not Use","0x60","0x68","0x69","0x7C"]},
      { label: 'Area Metering', group: ["0x63","0x76","0x24","0x77","0x5A","0x57","0x58","0x60","0x65","0x66","0x70","0x71"]},
      { label: 'Memory', group: ["0x67","0x26","0x26"]}
    ],
    sendHex: '',
    sendData: '',
    unitSettings: this.$store.getters.getSettings,
    unitCharge: this.$store.getters.getSettings.unitSettings[1].stopwatch,
    // unitDischarge: this.$store.getters.getSettings.unitSettings[1].stopwatch_discharge
    }
  },
  computed: {
    message() {
      let obj = {};
      obj.name = "Send Hex";
      obj.data = this.sendData;
      return obj;
    }
  }
};
</script>
