<template>
  <section v-if="unitID">
    <b-button @click="getData" type="is-info" class="mr-4" :loading="isLoading"
      >Get Data</b-button
    >

    <b-collapse :open="false" aria-id="contentIdForA11y2">
      <template #trigger="props">
        <b aria-controls="contentIdForA11y2">
          Quick Data
          <b-icon
            pack="fas"
            :icon="!props.open ? 'angle-down' : 'angle-up'"
          ></b-icon>
        </b>
      </template>
      <div class="notification">
        <div class="card-content">
          <div class="content">
            <div class="columns is-multiline mt-2">
              <b-field
                :label="item.name"
                v-for="item in fieldVars"
                :key="item.name"
                class="column is-one-quarter-desktop is-half-tablet"
              >
                <b-input
                  type="textarea"
                  custom-class="data-box"
                  :value="item.data"
                ></b-input>
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>

    <b-field label="Full Response" class="mt-2">
      <b-input
        :value="JSON.stringify(data)"
        type="textarea"
        height="100%"
        rows="16"
      ></b-input>
    </b-field>
  </section>
  <section v-else>
    <EmptyID @activetab="$emit('activetab', $event)" />
  </section>
</template>

<script>
import EmptyID from "@/components/EmptyID.vue";
import { getToken } from "../session.js";
import axios from "axios";

export default {
  name: "Data",
  components: { EmptyID },
  props: ["unitID", "token"],
  data() {
    return {
      isLoading: false,
      data: [],
      vars: [
      { name:  'SampleTime (str)',    attr: 'SampleTime.dtDateTime' },
      { name: 'Mains Voltage',        attr: 'VoltageMeterings[0].VoltageReading' },
      { name:  'Inverter Voltage',    attr: 'VoltageMeterings[1].VoltageReading' },
      { name:  'ChargerStatus',       attr: 'ChargerStatus' },
      { name:  'Battery Voltage',     attr: 'BatMetering.BatteryVoltageVolts' },
      { name:  'Battery Power',       attr: 'BatMetering.BatteryPowerWatts' },
      { name:  'Battery A',           attr: 'BatMetering.SenseCurrentAmps'},
      { name:  'Battery Charge',      attr: 'BatMetering.ChargePercentage' },
      { name:  'Temperature',         attr: 'BatMetering.TemperatureCelcius' },
      { name:  'Mains Freq',          attr: 'VoltageMeterings[0].Frequency' },
      { name:  'Inverter Freq',       attr: 'VoltageMeterings[1].Frequency' },
      { name:  'CT0 Power',           attr: 'OutputAreaMeterings[0].ChannelPowerWatts' },
      { name:  'CT1 Power',           attr: 'OutputAreaMeterings[1].ChannelPowerWatts' },
      { name:  'CT2 Power',           attr: 'OutputAreaMeterings[2].ChannelPowerWatts' },
      { name:  'Inverter Status [0]',    attr: 'OutputAreaMeterings[0].status' },
      { name:  'Inverter Status [1]',    attr: 'OutputAreaMeterings[1].status' },
      { name:  'Inverter Status [2]',    attr: 'OutputAreaMeterings[2].status' }
    ]
    }
  },
  methods: {
    async getData() {
      let vm = this;
      const token = await getToken();
      axios
        .get(process.env.VUE_APP_API_BASE + this.unitID + "/data", {
          headers: {
            "X-Amz-Security-Token": token,
          },
          params: {
            period: "last10",
          },
        })
        .then((response) => (vm.data = response.data.Items))
        .catch(function(error) {
          console.log(JSON.stringify(error));
        });
    },
  },
  computed: {
    fieldVars() {
      return this.vars.map((item) => {
        var container = {};
        container.name = item.name;
        container.attr = item.attr;
        //container.data = this.data.map(data => data[item.attr]).join();
        //container.data = b.map(data => item.attr.split('.').reduce((p,c)=>p&&p[c]||null, data)).join(); //these don't work for nested attributes
        container.data = this.data
          .map((data) => new Function("_", "return _." + item.attr)(data))
          .join(); //this needs changing

        return container;
      });
    },
  },
  created() {
    axios.interceptors.request.use(
      (config) => {
        //isLoading with axios
        this.isLoading = true;
        return config;
      },
      (error) => {
        this.isLoading = false;
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        this.isLoading = false;
        return response;
      },
      (error) => {
        this.isLoading = false;
        return Promise.reject(error);
      }
    );
  },
};
</script>

<style>
textarea.textarea.data-box {
  min-height: auto;
}
</style>
