<template>
    <b-button @click="sendSettings" type="is-info" class="mr-2 mb-2">
      {{name}}
       <b-modal v-model="isImageModalActive">
            <p class="image is-4by3">
                <img src="/static/img/placeholder-1280x960.png">
            </p>
        </b-modal>
    </b-button>
</template>

<script>
import axios from "axios";
import { getToken } from "../session";
//https://48927fuz4c.execute-api.eu-west-2.amazonaws.com/testV2/settings/boost/0000000001

export default {
  name: "SendMessageButton",
  props: ["unitID", "name", "type", "hasData", "hasTime"],
  data() {
    return {
      timePeriod: [
        { Start_Time: "00:00", Stop_Time: "00:00" },
        { Start_Time: "00:00", Stop_Time: "00:00" },
      ],
      isImageModalActive: false
    };
  },
  methods: {
    sendData(data) {
      const token = getToken();
      axios
        .post(
          process.env.VUE_APP_API_BASE + this.unitID + "/settings/" + this.type,
          data,
          {
            headers: {
              "X-Amz-Security-Token": token,
            },
          }
        )
        .then((response) =>
          this.$buefy.toast.open(`${this.type} sent: ${response}`)
        )
        .catch(function(error) {
          console.log(JSON.stringify(error));
        });

      // axios
      //     .get('https://48927fuz4c.execute-api.eu-west-2.amazonaws.com/testV2/' + this.unitID)
      //     .then(response => {this.$buefy.snackbar.open({
      //       message: '<i class="fas fa-check-circle"></i>' + response + '! Message sent: ' + this.item.hex,
      //       position: 'is-top',
      //       duration: 3000})
      //     })
      //     .catch(error => {this.$buefy.snackbar.open({
      //       message: '<i class="fas fa-check-circle"></i> Error!' + error,
      //       position: 'is-top',
      //       type: 'is-danger',
      //       duration: 4000})
      //     })
    },
    sendSettings() {
      if (this.hasData) {
        this.$buefy.dialog.prompt({
          message: "Minutes:",
          inputAttrs: {
            type: "number",
          },
          trapFocus: true,
          confirmText: "Send",
          onConfirm: (value) => this.sendData(this.boostPeriod(value)),
        });
      } else if (this.hasTime) {
        this.$buefy.dialog.prompt({
          container: "#dialogzz",
          message: "Time Periods",
          inputAttrs: {
            name: "time-input",
            value: JSON.stringify(this.displayTime),
            size: 120,
            width: "700px",
          },
          trapFocus: true,
          onConfirm: (value) =>
            this.$buefy.toast.open(`Your data is: ${value}`),
        });
      } else {
        this.sendData(this.boostPeriodNow);
      }
    },
    boostPeriodNow() {
      let start = new Date();
      let stop = new Date();
      let boost = {};
      stop.setMinutes(stop.getMinutes() + 15);
      boost.Start_Time = start.toISOString().substr(0, 19); //format date
      boost.Stop_Time = stop.toISOString().substr(0, 19);
      return boost;
    },
    boostPeriod(interval) {
      let start = new Date();
      let stop = new Date();
      let boost = {};
      stop.setMinutes(stop.getMinutes() + parseFloat(interval));
      boost.Start_Time = start.toISOString().substr(0, 19); //format date
      boost.Stop_Time = stop.toISOString().substr(0, 19);
      return boost;
    },
  },
  computed: {
    displayTime() {
      let string = "";
      let x;
      for (x in this.timePeriod) {
        string +=
          this.timePeriod[x].Start_Time +
          " - " +
          this.timePeriod[x].Stop_Time +
          ", ";
      }
      return string.slice(0, -2);
    },
  },
};

// const ModalForm = {
//         props: ['email', 'password'],
//         template: `
//             <form action="">
//                 <div class="modal-card" style="width: auto">
//                     <header class="modal-card-head">
//                         <p class="modal-card-title">Login</p>
//                         <button
//                             type="button"
//                             class="delete"
//                             @click="$emit('close')"/>
//                     </header>
//                     <section class="modal-card-body">
//                         <b-field label="Email">
//                             <b-input
//                                 type="email"
//                                 :value="email"
//                                 placeholder="Your email"
//                                 required>
//                             </b-input>
//                         </b-field>

//                         <b-field label="Password">
//                             <b-input
//                                 type="password"
//                                 :value="password"
//                                 password-reveal
//                                 placeholder="Your password"
//                                 required>
//                             </b-input>
//                         </b-field>

//                         <b-checkbox>Remember me</b-checkbox>
//                     </section>
//                     <footer class="modal-card-foot">
//                         <b-button
//                             label="Close"
//                             @click="$emit('close')" />
//                         <b-button
//                             label="Login"
//                             type="is-primary" />
//                     </footer>
//                 </div>
//             </form>
//         `
//     }
</script>

<style scoped>
.button.is-link {
  background-color: #3273dc;
  border-color: transparent;
  color: #fff;
}
.button.is-info.is-light {
  background-color: #eef6fc;
  color: #1d72aa;
}

.dialog .modal-card {
  max-width: 700px !important;
}
</style>
