import Vue from "vue";
import App from "./App.vue";
import Buefy from "buefy";
//import 'buefy/dist/buefy.css'
import router from "./router";
import store from "./store";
import { Amplify } from "aws-amplify";
import { config } from "./config";
import VueApexCharts from 'vue-apexcharts';

Vue.config.productionTip = false;

Vue.use(Buefy, {
  defaultIconPack: "fas",
});

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
