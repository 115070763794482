<template>
  <div id="app" class="is-fullheight">
    <div>
    <TheNavBar :isAuth="$store.getters.getIsAuth"/>
    </div>
    <section class="is-grey is-fullheight mt-3">
      <router-view class="container" />
    </section>
  </div>
</template>

<script>

// @ is an alias to /src
import TheNavBar from '@/components/TheNavBar.vue'

export default {
  name: 'App',
  components: {
    TheNavBar
  },
  data() {
    return {
      isAuth: this.$store.getters.getIsAuth
    }
  }
}

</script>

<style>
html, body{
  height: 100%;
}
.is-grey {
  background-color: rgb(241, 241, 241);
}
.is-fullheight{
  height: 100%;
}
</style>

<style lang="scss">

//$family-sans-serif: "Nunito", sans-serif;

@import "@/assets/_variables.scss"; //sometimes needs this, not sure why could be Vue Cli version?
@import "~bulma";

</style>