<template>
  <b-dropdown v-model="queryArray" :triggers="['hover']" multiple aria-role="list">
    <template #trigger>
      <b-button type="is-primary" icon-right="menu-down" class="mt-2">
        <!-- Selected ({{ selectedOptions.length }}) -->
        {{label}} 
      </b-button>
    </template>

    <b-dropdown-item  v-if="!isObject" :value="data" aria-role="listitem">
      <span>{{data}}</span>
    </b-dropdown-item>

    <b-dropdown-item v-else v-for="item in data[label]" :key="item" :value="label + '.' + item" aria-role="listitem">
      <span>{{item}}</span>
    </b-dropdown-item>

  </b-dropdown>
</template>

<script>
export default {
  name: "QuerySelect",
  props: ['data', 'queryArray'],
  data() {
    return {
      isObject: typeof this.data === 'object'
    }
  },
  computed: {
    label() {
      if (this.isObject){
        return Object.keys(this.data)[0]
      }
      else {
        return this.data
      }
    }
  }
};
</script>
