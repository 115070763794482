<template>
  <b-message type="is-info" has-icon>
      <p class="mb-3">
        Log into a unit to use this tab
      </p>
      <b-button @click="$emit('activetab', 0)" type="is-info" >Go to Units</b-button>
  </b-message>
</template>

<script>
export default {
  name: "EmptyID",
};
</script>
